<template>
  <div class="content">
    <md-result-page v-if="!this.list ||!this.list.length" :text="emptyText" class="empty-page" />
    <div v-else>
      <div class="product-list-box">
        <div class="product-list">
          <div v-for="item in list" :key="item.id" :class="{active: item.active}" @click="choose(item.id)" class="product-wrap">
              <div class="product-box">
                <div class="product-name">{{item.name}}</div>
                <div>最高{{item.maxAmount | parseInt}}万</div>
                <div class="product-interest">{{item.rateUnit}}息{{item.rate}}</div>
              </div>
              <div class="arrow"></div>
          </div>
        </div>
      </div>
      <div class="product-detail">
        <div class="logo">
          <img v-bind:src="detail.logoUrl">
          <label>{{detail.name}}<span class="product-id">( 产品代码：{{detail.id}} )</span></label>
        </div>
        <div class="basic-info">
          <div class="basic-info-item">
            <div>最高额度：</div>
            <div><span class="value">{{detail.maxAmount | parseInt}}</span>万元</div>
          </div>
          <div class="basic-info-item">
            <div>参考{{detail.rateUnit}}息：</div>
            <div><span class="value">{{detail.rate}}</span></div>
          </div>
        </div>
        <div v-if="type != 1" class="tips">
          <div class="tips-title">不符准入原因：</div>
          <div v-for="item in tips" :key="item" class="tips-content">{{item}}</div>
        </div>
        <div v-if="warnings.length" class="warnings">
          <div class="warnings-title">风险警告：</div>
          <div v-for="item in warnings" :key="item" class="warnings-content">{{item}}</div>
        </div>
        <div class="more-detail">
          <div class="detail-item">最高期限：{{detail.lengthOfMaturity}}个月</div>
          <div class="detail-item">还款方式：{{detail.repayment}}</div>
          <div class="detail-item">申请条件：</div>
          <div class="requirement-text" v-html="detail.requirement"></div>
        </div>
      </div>
    </div>
<!--    <md-action-bar v-show="type === 1" :actions="actionData"></md-action-bar>-->
  </div>
</template>

<script>
  import {ActionBar, ResultPage} from 'mand-mobile'
  import OrderApi from '../../../api/order'

  export default {
  name: 'jzsy-result-detail',
  components: {[ActionBar.name]: ActionBar, [ResultPage.name]: ResultPage},
  props: {
    products: Array,
    type: {
      type: Number,
      default: 1 // 1-符合准入，2-不符准入
    },
    taxpayerId: String
  },
  data() {
    return {
      list: this.products,
      detail: this.products[0],
      actionBarLoading: false
    }
  },
  computed: {
    emptyText() {
      if (this.type === 1) {
        return '暂无符合准入的产品';
      } else {
        return '无不符准入的产品';
      }
    },
    tips() {
      let tempArr = []
      if (this.detail.tips) {
        tempArr = this.detail.tips.split(';')
      }
      return tempArr;
    },
    warnings() {
      let tempArr = []
      if (this.detail.warnings) {
        tempArr = this.detail.warnings.split(';')
      }
      return tempArr
    },
    actionData() {
      if (this.detail && this.detail.online) {
        return [
          {
            text: '立即申请',
            loading: this.actionBarLoading,
            inactive: this.actionBarLoading,
            onClick: this.gotoApply
          },
        ]
      } else {
        return [
          {
            text: '联系客服',
            onClick: this.contact
          },
        ]
      }
    }
  },
  watch: {
    products(val) {
      this.list = val;
      this.detail = val[0];
    }
  },
  methods: {
    choose(id) {
      this.list = this.list.map(e => {
        if (id === e.id) {
          e.active = true;
          this.detail = e;
        } else {
          e.active = false;
        }
        return e;
      });
    },
    gotoApply() {
      this.actionBarLoading = true;
      OrderApi.applyLink(this.detail.id, this.taxpayerId, this.detail.muuid).then(res => {
        location.href = res.data.url
      }).finally(() => {
        setTimeout(() => {
          this.actionBarLoading = false;
        }, 1000)
      })
    },
    contact() {
      this.$dialog.alert(
          {
            title: '联系客服',
            content: '电话： <a href="tel://17318699151">17318699151</a>　　　<a href="tel://19136244595">19136244595</a>'}
        )
    }
  },
  filters: {
    parseInt(val) {
      return parseInt(val);
    }
  }
}
</script>

<style scoped>
  .content {
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 24px;
  }
  .product-list-box {
    overflow: auto;
  }
  .product-list {
    display: block;
    margin: 0 auto;
    white-space: nowrap;
    width: fit-content;
  }
  .product-wrap {
    display: inline-block;
  }
  .product-box {
    width: 120px;
    height: 200px;
    background-color: #9d64ff;
    color: #ffffff;
    margin: 0 10px;
    text-align: center;
    padding: 5px;
    white-space: normal;
    overflow: hidden;
    border-radius: 25px 0 25px 0;
  }
  .product-name {
    margin: 30px 0;
  }
  .product-id {
    margin-left: 20px;
    font-size: 24px;
  }
  .product-interest {
    margin-top: 10px;
    font-size: 20px;
  }
  .product-wrap.active .product-box {
    background-color: #7147B5;
  }
  .product-wrap.active .arrow {
    border: 20px solid;
    border-color: transparent transparent #7147B5;
    width: 0;
    height: 0;
    margin: 0 auto;
  }
  .product-detail {
    min-height: 600px;
    border: 1px solid #7147b5;
    border-radius: 20px;
    padding: 50px;
  }
  .logo {
    display: flex;
    align-items: center;
  }
  .logo label {
    color: #7147B5;
    font-size: 50px;
    margin-left: 20px;
  }
  .logo img {
    width: 100px;
  }
  .basic-info {
    display: flex;
    margin: 30px 0;
  }
  .basic-info-item {
    flex-grow: 1;
    display: flex;
    flex-direction:column;
    align-items: center;
  }
  .basic-info-item:first-child {
    border-right: 1px solid #e3e3e3;
  }
  .basic-info .value {
    font-size: 50px;
    line-height: 100px;
    color: #f57500;
  }
  .more-detail {
    border-top: 1px solid #e3e3e3;
    padding-top: 30px;
  }
  .detail-item {
    line-height: 50px;
  }
  .requirement-text {
    line-height: 36px;
    margin-left: 20px;
  }
  .empty-page {
    height: 50vh;
  }
  .tips, .warnings {
    line-height: 42px;
    margin-bottom: 20px;
  }
  .tips .tips-title, .warnings .warnings-title {
    color: rgb(255, 0, 0);
  }
  .tips .tips-content, .warnings .warnings-content {
    color: #f57500;
  }
</style>