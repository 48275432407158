<template>
  <div class="wrapper" v-title="enterpriseName + ' | 匹配结果'">
      <jzsy-header :title="enterpriseName"></jzsy-header>
      <md-skeleton avatar title :loading="skeletonLoading" :class="{skeleton: skeletonLoading}">
        <md-water-mark>
          <div slot="watermark" spacing="10vw" class="water-mark-content">
            <span>橘子税银</span>
          </div>
          <md-tabs>
            <md-tab-pane name="p1" :label="qualifiedLabel" style="transform: none">
              <jzsy-result-detail :products="qualified" :taxpayerId="taxpayerId"></jzsy-result-detail>
            </md-tab-pane>
            <md-tab-pane name="p2" :label="unqualifiedLabel" style="transform: none">
              <jzsy-result-detail :products="unqualified" :type="2" :taxpayerId="taxpayerId"></jzsy-result-detail>
            </md-tab-pane>
          </md-tabs>
          <div class="tips">匹配时间：{{matchedDate}}</div>
          <div class="tips">提示：该匹配结果仅作为基本准入参考，最终审批结果以银行审批为准。</div>
        </md-water-mark>
      </md-skeleton>
  </div>
</template>

<script>
import {Tabs, TabPane} from 'mand-mobile'
import { WaterMark } from 'mand-mobile'
import productMatch from '@/api/productMatch'
import ResultDetail from './ResultDetail.vue'
import { Skeleton } from 'mand-mobile'
// import WeixinApi from '@/api/weixin'
// const wx = require('weixin-js-sdk')

export default {
  components: {
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [WaterMark.name]: WaterMark,
    [ResultDetail.name]: ResultDetail,
    [Skeleton.name]: Skeleton
  },
  props: {
    id: String
  },
  data() {
    return {
      enterpriseName: '',
      taxpayerId: '',
      qualified: [],
      unqualified: [],
      skeletonLoading: true,
      matchedDate: ''
    }
  },
  computed: {
    qualifiedLabel() {
      return `符合准入（${this.qualified.length}）`
    },
    unqualifiedLabel() {
      return `不符准入（${this.unqualified.length}）`
    }
  },
  created() {
    productMatch.query(this.id)
      .then(res => {
        this.enterpriseName = res.data.enterpriseName;
        this.taxpayerId = res.data.taxpayerId;
        this.matchedDate = res.data.matchedDate;
        this.qualified = res.data.qualifiedList;
        this.unqualified = res.data.unqualifiedList;
        if (this.qualified && this.qualified.length) {
          this.qualified[0].active = true;
        } 
        if (this.unqualified && this.unqualified.length) {
          this.unqualified[0].active = true;
        }
        this.skeletonLoading = false;
      })

    // WeixinApi.sign(location.href.split('#')[0]).then(res => {
    //   wx.config({
    //     debug: true,
    //     appId: res.data.appid,
    //     timestamp: res.data.timestamp,
    //     nonceStr: this.id,
    //     signature: res.data.signature,
    //     jsApiList: ['onMenuShareAppMessage']
    //   })
    // })
    //
    // wx.ready(function () {
    //   wx.onMenuShareAppMessage({
    //     title: this.enterpriseName,
    //     desc: '点击查看详情',
    //     link: 'http://open.shujet.com/product/match/result/' + this.results.matchedUuid,
    //     imgUrl: 'https://jzsy-img.caishuib.com/img/logo/201909/b096a639dfaed8622ae4b64534c642d8.png',
    //   })
    // })
  }
}
</script>

<style scoped>
  .wrapper {
    margin-bottom: 120px;
    font-size: 24px;
  }
  .water-mark-content span {
    font-size: 64px;
  }
  .tips {
    width: 95%;
    margin: 20px auto;
    color: #646464;
    line-height: 38px;
  }
</style>